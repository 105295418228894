@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bs-body-font-size: 0.9rem;
}

#root {
  display: flex;
  flex-direction: column;
}

#bookings {
  flex-grow: 1;
  display: flex;
  position: relative;
  min-height: 0;
  width: 100vw;
  height: calc(100vh - 42px);
}

#chart {
  flex-grow: 1;
  height: 100%;
  position: relative;
}

#svg {
  width: 100%;
  height: 100%;
}

#sidebar {
  height: 100%;
  position: relative;
}

#sidebar-items {
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

#groups {
  overflow: auto;
}

#tooltip {
  display: none;
  position: absolute;
  padding: 10px 15px;
  border-radius: 6px;
  background: #f5df4d;
  white-space: nowrap;
  text-align: left;
}

.form-check-input:focus {
  box-shadow: none;
}

#all {
  width: 1.3em;
  height: 1.3em;
  border-width: 2px;
  margin-top: 0.15em;
}

.form-check-input.group {
  width: 1.3em;
  height: 1.3em;
  border-color: var(--form-check-input-checked-border-color);
  border-width: 2px;
  margin-top: 0.15em;
}

.form-check-input.group:checked {
  background-color: var(--form-check-input-checked-bg-color);
  border-color: var(--form-check-input-checked-border-color);
}

.filter {
  height: 0.6rem;
  cursor: pointer;
}

.fade {
  background: black;
  opacity: 0.3;
}

.curTable {
  font-size: 12px;
  table-layout: fixed;
}

.dateColumn {
  width: 105px;
}

.locationSelect {
  width: 150px;
  min-width: 150px;
}

.loader {
  z-index: 2000;
}

.curDashboard > * {
  margin-right: 0.75rem;
}

.multiSelect > * {
  margin: 0.1rem 0.1rem;
}

.multiSelect > button {
  border: 1px solid var(--bs-primary);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
  color: var(--bs-primary);
  background-color: #fff;
}

.multiSelect > button.active {
  border-color: var(--bs-primary);
  color: white;
  background-color: var(--bs-primary);
}
